import React from "react";
import { Text, Box } from "@chakra-ui/react";
import BoxCard from "@/components/common/BoxCard";
import { ImageAutoHeight } from "@/components/common/Image";
import { ArticleInputs } from "@/types/office";
import { format } from "date-fns";
import AudioVideoPlayer from "@/components/common/AudioVideoPlayer";
import { CloudType } from "@/types/cloud";
import ParsedHTML from "@/components/common/ParsedHTML";
import { typedFile } from "@/utils/typedFile";
import { useSession } from "next-auth/client";

const Article = ({
  article,
  locale = "pl",
  rounded = true,
}: {
  article: ArticleInputs;
  rounded?: boolean;
  locale?: string;
}): JSX.Element => {
  const session = useSession();
  const typedWebFile = typedFile(article?.webFile);
  const image =
    typedWebFile?.type === CloudType.PHOTO
      ? `/cloud/file/${typedWebFile.filename}`
      : null;

  const audioVideo =
    typedWebFile?.type === CloudType.VIDEO ||
    typedWebFile?.type === CloudType.AUDIO
      ? typedWebFile
      : null;

  return (
    <BoxCard borderRadius={rounded ? ".375rem" : 0}>
      <Text as="h1" fontSize="2xl" fontWeight="500" lineHeight={1.2}>
        {article?.webTopic?.[locale]}
      </Text>
      <Text color="text.700" mt={3}>
        {format(new Date(article?.publicationDate), "dd/MM/yyyy")}
      </Text>
      {(image || audioVideo) && (
        <Box
          w={{ base: "100%", md: "600px" }}
          position="relative"
          borderRadius={rounded ? ".375rem" : 0}
          overflow="hidden"
          mt={6}
          mx="auto"
        >
          {image && <ImageAutoHeight src={image} layout="fill" alt="" />}
          {audioVideo && (
            <AudioVideoPlayer
              downloadAvailable={session[0] ? true : false}
              file={audioVideo}
              rounded={rounded}
            />
          )}
        </Box>
      )}
      <Box textAlign="justify" mt={6}>
        {article?.webShortDescription && (
          <Box fontWeight={"bold"} mb={2}>
            <ParsedHTML html={article.webShortDescription[locale]} />
          </Box>
        )}
        {article?.webContent && (
          <ParsedHTML html={article.webContent[locale]} />
        )}
      </Box>
    </BoxCard>
  );
};

export default Article;

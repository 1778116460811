import React from "react";
import DOMPurify from "isomorphic-dompurify";
import { CloudType } from "@/types/cloud";
import { useDebounce } from "@/hooks/useDebounce";
import { transformColor } from "@/utils/colorSchemes";

import AudioVideoPlayer from "./AudioVideoPlayer";
import { Tweet } from "react-twitter-widgets";

import parse, {
  HTMLReactParserOptions,
  Element,
  attributesToProps,
} from "html-react-parser";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  // add rel noopener to prevent window.opener tracking
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }

  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});

type Props = {
  html?: string;
};

const StyledParsedHTML = styled.div`
  .large,
  [data-size="Large"] {
    width: 100%;
  }
  .medium,
  [data-size="Medium"] {
    width: 60%;
  }
  .small,
  [data-size="Small"] {
    width: 30%;
  }
  a {
    color: ${() => transformColor("main.600")};
    font-weight: bold;

    &:hover {
      color: ${() => transformColor("main.800")};
    }
  }
  h1 {
    font-size: 2.25rem;
    line-height: 1.33;
  }
  h2 {
    font-size: 1.875rem;
    line-height: 1.33;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.33;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.33;
  }
  h5 {
    font-size: 1.125rem;
    line-height: 1.33;
  }
  li{
    margin-left: 30px;
  }
  blockquote {
    border-left-width: 5px;
    border-color: ${() => transformColor("main.800")};
    padding-left: 30px;
  }
  .ql-indent-1 {
    margin-left: 60px;
  }
  .ql-indent-2 {
    margin-left: 90px;
  }
  .ql-indent-3 {
    margin-left: 120px;
  }
  .ql-indent-4 {
    margin-left: 150px;
  }
  .ql-indent-5 {
    margin-left: 180px;
  }
  .ql-cloudlink {
    display: inline;
  }
  .ql-align-center {
    text-align: center;

    img,
    iframe {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .ql-align-right {
    text-align: right;

    img,
    iframe {
      display: inline-block;
    }
  }
  .ql-align-justify {
    text-align: justify;
  }
  div.ql-audio.ql-align-center, div.ql-video.ql-align-center {
    margin-left: auto;
    margin-right: auto;
  }
  .ql-align-right {
    img, iframe {
      display: inline-block;
    }
  }
  div.ql-audio.ql-align-right, div.ql-video.ql-align-right {
    margin-left: auto;
  },
`;

const ParsedHTML = ({ html }: Props): JSX.Element => {
  const debouncedHTML = useDebounce(html, 500);
  const cleanHTML = DOMPurify.sanitize(debouncedHTML);

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (!(domNode instanceof Element && domNode.attribs)) return;
      const props = attributesToProps(domNode.attribs);

      if (domNode.attribs.class?.includes("ql-video")) {
        const filename = domNode.attribs["data-filename"];

        return (
          <Box {...props}>
            <AudioVideoPlayer
              file={{ type: CloudType.VIDEO, filename }}
              autoStart={false}
            />
          </Box>
        );
      } else if (domNode.attribs.class?.includes("ql-audio")) {
        const filename = domNode.attribs["data-filename"];

        return (
          <Box {...props}>
            <AudioVideoPlayer
              file={{ type: CloudType.AUDIO, filename }}
              autoStart={false}
            />
          </Box>
        );
      } else if (domNode.attribs.class?.includes("ql-youtube")) {
        const id = domNode.attribs["data-id"];

        return (
          <Box {...props}>
            <iframe
              src={`https://www.youtube.com/embed/${id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        );
      } else if (domNode.attribs.class === "ql-tweet") {
        const id = domNode.attribs["data-id"];

        return <Tweet tweetId={id} options={{ width: 200 }} />;
      }
    },
  };

  return <StyledParsedHTML>{parse(cleanHTML, options)}</StyledParsedHTML>;
};

export default ParsedHTML;

import React from "react";
import { useRouter } from "next/router";
import {
  Tooltip,
  Flex,
  Text,
  Heading,
  Box,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ScaleFade,
} from "@chakra-ui/react";
import Avatar from "@/components/common/Avatar";
import BoxCard from "@/components/common/BoxCard";
import { MoreVertical, Edit2, Globe } from "react-feather";
import Link from "@/components/common/Link";
import { Image, ImageWithLoader } from "@/components/common/Image";
import Article from "./Article";
import { ArticleInputs } from "@/types/office";
import { useTranslation } from "next-i18next";
import DeleteDialog from "@/components/common/DeleteDialog";
import { ArticleType } from "@/types/office";
import { format } from "date-fns";
import AudioVideoPlayer from "@/components/common/AudioVideoPlayer";
import FileTag from "@/components/common/FileTag";
import { CloudType } from "@/types/cloud";
import { Website } from "@/types/website";
import paths from "@/utils/paths";
import ParsedHTML from "@/components/common/ParsedHTML";
import { prependURL } from "@/utils/helpers";

type Props = {
  visible?: boolean;
  admin?: boolean;
  preview?: boolean;
  rounded?: boolean;
  article: ArticleInputs;
  website?: Website;
  handleEditClick?: () => void;
  onDeleteNews?: () => void;
  onNewsTypeChange?: () => void;
  title?: string;
  lines?: number;
  language?: string;
};

const Excerpt = ({
  visible = true,
  admin = false,
  preview = false,
  rounded = true,
  article,
  website = null,
  title,
  lines = 5,
  handleEditClick,
  onDeleteNews,
  onNewsTypeChange,
  language = null,
}: Props): JSX.Element => {
  const router = useRouter();
  const { locale = "pl" } = router;
  const { t } = useTranslation("common");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFull,
    onOpen: onOpenFull,
    onClose: onCloseFull,
  } = useDisclosure();

  const onDelete = () => {
    onDeleteNews();
    onClose();
  };

  const articleLocale = getArticleLocale(article, language, locale);

  const image =
    article?.webFile?.type === CloudType.PHOTO
      ? `/cloud/file/${article?.webFile?.filename}`
      : null;

  const audioVideo =
    article?.webFile?.type === CloudType.VIDEO ||
    article?.webFile?.type === CloudType.AUDIO
      ? article?.webFile
      : null;

  const articleURL = paths.websiteArticle(
    website?.slug,
    website?.isSubdomain,
    article?.slug
  );

  let pressOfficeLink;

  if (website && article?.pressOffice?.url) {
    pressOfficeLink = prependURL(article?.pressOffice?.url);
  }

  if (!website) pressOfficeLink = `/offices/office/${article?.pressOffice?.id}`;

  return (
    <>
      {admin && (
        <DeleteDialog
          isOpen={isOpen}
          onClose={onClose}
          onDelete={onDelete}
          name={t("deleteConfirm.pressInfo")}
        />
      )}
      <ScaleFade initialScale={0.9} in={visible}>
        <Flex direction="column" h="100%">
          <BoxCard
            flex={1}
            p={0}
            borderRadius={rounded ? ".375rem" : "0"}
            opacity={article?.type === ArticleType.DRAFT ? "70%" : "100%"}
            display="flex"
            flexDirection="column"
          >
            <Flex
              py={3}
              px={4}
              align="center"
              justify="space-between"
              borderBottomColor="light.50"
              borderBottomWidth="1px"
              zIndex={3}
            >
              <Flex align="center" mr={3} flex="1">
                {article?.pressOffice?.picture && (
                  <>
                    {pressOfficeLink ? (
                      <Link to={pressOfficeLink}>
                        <Avatar
                          src={`/uploads/${article?.pressOffice?.picture}`}
                          size="md"
                          mr={3}
                          name={article?.pressOffice?.name}
                        />
                      </Link>
                    ) : (
                      <Avatar
                        src={`/uploads/${article?.pressOffice?.picture}`}
                        size="md"
                        mr={3}
                        name={article?.pressOffice?.name}
                      />
                    )}
                  </>
                )}
                <Box>
                  {article?.pressOffice ? (
                    <>
                      {pressOfficeLink ? (
                        <Link to={pressOfficeLink}>
                          <Text fontWeight="500">
                            {admin
                              ? `${article?.author?.firstName} ${article?.author?.lastName}`
                              : article?.pressOffice?.name}
                          </Text>
                        </Link>
                      ) : (
                        <Text fontWeight="500">
                          {admin
                            ? `${article?.author?.firstName} ${article?.author?.lastName}`
                            : article?.pressOffice?.name}
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text fontWeight="500">
                      {admin
                        ? `${article?.author?.firstName} ${article?.author?.lastName}`
                        : article?.pressOffice?.name}
                    </Text>
                  )}
                  {article?.publicationDate ? (
                    <Text
                      color="text.500"
                      fontSize="xs"
                      lineHeight="1rem"
                      mt=".125rem"
                    >
                      {format(new Date(article?.publicationDate), "dd/MM/yyyy")}
                    </Text>
                  ) : null}
                </Box>
              </Flex>
              {admin && (
                <>
                  {article?.type === ArticleType.DRAFT && (
                    <Tooltip hasArrow label={t("press.draft")} placement="top">
                      <Box
                        mr={2}
                        color="text.500"
                        onClick={() => router.push(`/press/edit/${article.id}`)}
                        cursor="pointer"
                      >
                        <Edit2 size={15} />
                      </Box>
                    </Tooltip>
                  )}
                  <Menu placement="bottom-end">
                    <MenuButton role="group">
                      <Icon as={MoreVertical} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={onNewsTypeChange}>
                        {t(`${title}`)}
                      </MenuItem>
                      <MenuItem onClick={handleEditClick}>{t("edit")}</MenuItem>
                      <MenuItem onClick={onOpen}>{t("delete")}</MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </Flex>
            <Box p={5} flex={1}>
              {image || audioVideo ? (
                !website ? (
                  <Box
                    w="100%"
                    h="auto"
                    position="relative"
                    borderRadius={rounded ? ".375rem" : "0"}
                    overflow="hidden"
                    onClick={onOpenFull}
                    cursor="pointer"
                    mb={5}
                    _before={{
                      content: '""',
                      w: "100%",
                      pt: "50%",
                      display: image ? "block" : "none",
                    }}
                  >
                    {image && (
                      <ImageWithLoader
                        priority
                        src={image}
                        objectFit="cover"
                        alt=""
                      />
                    )}
                    {audioVideo && (
                      <AudioVideoPlayer
                        file={audioVideo}
                        autoStart={false}
                        noEvents
                        rounded={rounded}
                        stopPlayer={isOpenFull}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    w="100%"
                    h={image ? "10rem" : "auto"}
                    position="relative"
                    borderRadius={rounded ? ".375rem" : "0"}
                    overflow="hidden"
                    cursor="pointer"
                    mb={5}
                  >
                    <Link to={articleURL}>
                      {image && (
                        <Image priority src={image} objectFit="cover" alt="" />
                      )}
                      {audioVideo && (
                        <AudioVideoPlayer
                          file={audioVideo}
                          autoStart={false}
                          noEvents
                          rounded={rounded}
                        />
                      )}
                    </Link>
                  </Box>
                )
              ) : null}
              {admin || preview ? (
                <Heading
                  size="md"
                  as="h3"
                  onClick={onOpenFull}
                  cursor="pointer"
                >
                  {article?.webTopic?.[articleLocale]}
                </Heading>
              ) : !website ? (
                <Box onClick={onOpenFull} cursor="pointer">
                  <Heading size="md" as="h3" mt={5} cursor="pointer">
                    {article?.webTopic?.[articleLocale]}
                  </Heading>
                </Box>
              ) : (
                <Heading size="md" as="h3" mt={5} cursor="pointer">
                  <Link to={articleURL}>
                    {article?.webTopic?.[articleLocale]}
                  </Link>
                </Heading>
              )}
              <Text mt={2} noOfLines={lines}>
                {(!!article?.webShortDescription ||
                  (!!article?.webContent && !preview)) && (
                  <ParsedHTML
                    html={
                      article?.webShortDescription?.[articleLocale] ??
                      article?.webContent?.[articleLocale]
                    }
                  />
                )}
              </Text>
              {!admin && article?.tags && (
                <Flex mt={4} mx="-2px" wrap="wrap">
                  {(article?.tags || [])?.map((tag) => (
                    <FileTag
                      key={`tag-${tag.id}`}
                      name={tag?.name}
                      colorScheme={tag?.tagGroup?.colorScheme}
                      picture={tag?.picture}
                      mx="2px"
                      pl={tag?.picture ? "10.5px" : "7.5px"}
                    />
                  ))}
                </Flex>
              )}
            </Box>
            {admin &&
              (article?.tags ||
                article?.webTopic ||
                article?.facebookContent ||
                article?.twitterContent) && (
                <Flex
                  py={3}
                  px={4}
                  align="center"
                  justify="space-between"
                  borderTopColor="light.50"
                  borderTopWidth="1px"
                >
                  {(article?.tags || [])?.map((tag) => (
                    <Flex key={`tag-${tag.id}`} mx="-2px" wrap="wrap">
                      <FileTag
                        name={tag?.name}
                        hideName
                        colorScheme={tag?.tagGroup?.colorScheme}
                        picture={tag?.picture}
                        size="sm"
                        m="2px"
                      />
                    </Flex>
                  ))}

                  <Flex wrap="wrap" ml="auto">
                    {article?.webTopic ? (
                      <Flex
                        align="center"
                        justify="center"
                        w="32px"
                        h="32px"
                        borderRadius="32px"
                        borderWidth="1px"
                        borderColor="light.50"
                        ml={2}
                        color="text.500"
                      >
                        <Icon as={Globe} />
                      </Flex>
                    ) : null}
                    {article?.facebookContent ? (
                      <Flex
                        align="center"
                        justify="center"
                        w="32px"
                        h="32px"
                        borderRadius="32px"
                        borderWidth="1px"
                        borderColor="light.50"
                        ml={2}
                      >
                        <Box w="16px" h="16px" position="relative">
                          <Image src="/facebook.png" alt="" />
                        </Box>
                      </Flex>
                    ) : null}
                    {article?.twitterContent ? (
                      <Flex
                        align="center"
                        justify="center"
                        w="32px"
                        h="32px"
                        borderRadius="32px"
                        borderWidth="1px"
                        borderColor="light.50"
                        ml={2}
                      >
                        <Box w="16px" h="16px" position="relative">
                          <Image src="/twitter.png" alt="" />
                        </Box>
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>
              )}
          </BoxCard>
        </Flex>
      </ScaleFade>
      {!website && (
        <Modal
          motionPreset="slideInBottom"
          isOpen={isOpenFull}
          onClose={onCloseFull}
          preserveScrollBarGap
        >
          <ModalOverlay>
            <ModalContent
              borderRadius=".375rem"
              w={{ base: "100%", md: "calc(60vw - 165px)" }}
              maxW="none"
            >
              <ModalHeader
                fontSize="1rem"
                fontWeight="500"
                borderBottomColor="light.50"
                borderBottomWidth="1px"
              >
                &nbsp;
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Article article={article} locale={articleLocale} />
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </>
  );
};

export const getArticleLocale = (
  article: ArticleInputs,
  language: string = null,
  locale = "pl"
): string => {
  if (!article) return null;
  if (language && article?.webTopic?.[language]) return language;
  if (article?.webTopic?.[locale]) return locale;
  if (article?.webTopic?.["en"]) return "en";

  return (
    article?.webTopic &&
    Object.keys(article?.webTopic)?.find(
      (lng) => article?.webTopic?.[lng]?.length > 0
    )
  );
};

export default Excerpt;

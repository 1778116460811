import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { colorSchemes } from "@/utils/colorSchemes";
import { OfficeType } from "@/types/office";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  name: string;
  officeTypeSignout?: string;
};

const DeleteDialog = ({
  isOpen,
  onClose,
  onDelete,
  name,
  officeTypeSignout = null,
}: Props): React.ReactElement => {
  const cancelRef = React.useRef();
  const { t } = useTranslation("common");

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      preserveScrollBarGap
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            {!officeTypeSignout
              ? t("deleteWithName", { name })
              : officeTypeSignout === OfficeType.PRESS
              ? t("office.signmeoutPressOfficeQuestion", { name })
              : t("office.signmeoutEditorialOfficeQuestion", { name })}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {!officeTypeSignout
              ? t("deleteConfirm.question", { name })
              : officeTypeSignout === OfficeType.PRESS
              ? t("office.signmeoutPressOfficeInformation")
              : t("office.signmeoutEditorialOfficeInformation")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button onClick={onDelete} ml={3} {...colorSchemes("red")}>
              {!officeTypeSignout ? t("delete") : t("office.signmeout")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteDialog;

import { CloudType, File } from "@/types/cloud";

export type BackendFile = Omit<File, "name"> & { originalname: string };

export const typedFile = (file: BackendFile): File => {
  if (!file) return null;

  const extension = file?.filename?.split(".")?.pop();
  const tagIds = file?.tags?.map(({ id }) => id);
  let type = CloudType.OTHER;
  if (file?.mimetype?.startsWith("image")) type = CloudType.PHOTO;
  else if (file?.mimetype?.startsWith("video")) type = CloudType.VIDEO;
  else if (file?.mimetype?.startsWith("audio")) type = CloudType.AUDIO;

  return {
    ...file,
    name: file?.originalname,
    extension,
    type,
    tagIds,
  };
};

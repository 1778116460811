import React, { MouseEvent } from "react";
import { Tag, TagProps, ThemingProps, Tooltip } from "@chakra-ui/react";
import { colorSchemes, ColorSchemes } from "@/utils/colorSchemes";
import Avatar from "@/components/common/Avatar";

type Props = {
  name: string;
  hideName?: boolean;
  picture?: string;
  colorScheme?: ColorSchemes;
  size?: ThemingProps<"Avatar">["size"];
  onClick?: (e: MouseEvent) => void;
};

const FileTag = ({
  name,
  hideName,
  picture,
  colorScheme = "gray",
  size = "2xs",
  onClick,
  ...rest
}: Props & TagProps): React.ReactElement => (
  <>
    {!hideName ? (
      <Tag colorScheme={colorScheme} m="2px" {...rest}>
        {picture && (
          <Avatar
            src={`/uploads/${picture}`}
            name={name}
            size={size}
            ml={-1}
            mr={2}
            bg="darkgray"
            onClick={onClick}
          />
        )}
        {name}
      </Tag>
    ) : (
      <Tooltip label={name} {...rest}>
        <Avatar
          src={picture ? `/uploads/${picture}` : null}
          name={name}
          size={size}
          mx={1}
          p={1}
          onClick={onClick}
          {...colorSchemes(colorScheme)}
        />
      </Tooltip>
    )}
  </>
);

export default FileTag;
